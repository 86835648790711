import { useEffect, useState } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import { cours } from '../../data/data';
import AdminCoursItem from '../../components/admin/AdminCoursItem';
import Pagination from '../../components/pagination/Pagination';
import HeaderCourse from '../../components/admin/HeaderCourse';
import { useGetCoursesQuery } from '../../redux/slices/courseApiSlice';
import Spinner from '../../components/spinner/Spinner';

const AdminCourses = () => {
  const [title, setTitle] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const { data, isLoading, isSuccess, refetch } = useGetCoursesQuery({
    currentPage,
    title,
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <main>
      <AdminLayout>
        <div className=' mt-3 md:mt-0 mb-3 w-full'>
          <HeaderCourse title={title} setTitle={setTitle} />
          {errorMessage && (
            <div className='w-full flex p-2 mb-2 items-center bg-red-100 space-x-1'>
              <span className='flex text-red-600'>{errorMessage}</span>
            </div>
          )}
          <div className=' w-full grid-rows-2  grid-cols-1 grid md:grid-cols-2 xl:grid-cols-3 gap-6'>
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                {data.courses.map((item) => (
                  <AdminCoursItem
                    refetch={refetch}
                    item={item}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    key={item.id}
                  />
                ))}
              </>
            )}
          </div>

          {data && data.courses && data.courses.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPages={data?.pages}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </AdminLayout>
    </main>
  );
};

export default AdminCourses;
