import React, { useEffect, useState } from 'react';
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Payment = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;

  const [clientId, setClientId] = useState('');
  const [loading, setLoading] = useState(true);
  const [{ isPending }] = usePayPalScriptReducer();
  const [price, setPrice] = useState(27);

  const navigate = useNavigate();

  const getPaypalClient = async () => {
    try {
      const response = await axios.get('/api/paypal');
      const { clientId } = response.data;
      setClientId(clientId);
      setLoading(false);
    } catch (error) {
      console.error('Error retrieving PayPal client ID:', error);
      toast.error(
        "Impossible de récupérer l'identifiant PayPal. Veuillez réessayer.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user) {
      navigate('/');
    } else {
      getPaypalClient();
    }
  }, [user, navigate]);

  const onError = () => {
    toast.error("Une erreur s'est produite lors du paiement.", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const onApprove = async (data, actions) => {
    try {
      const details = await actions.order.capture();
      setLoading(true);

      await axios.put(
        '/api/cours/update-payment',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success('Paiement réussi !', {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);

      window.location.reload();
    } catch (error) {
      console.error('Payment update error:', error);
      toast.error(
        "Une erreur s'est produite lors de la mise à jour du paiement.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      setLoading(false);
    }
  };

  return (
    <div className='w-full md:w-[60%] min-h-70vh flex justify-center items-center mx-auto text-center p-10'>
      <div className='mx-auto text-center'>
        {loading ? (
          <div className='flex justify-center w-full h-full'>
            <ThreeDots
              height={80}
              width={80}
              radius={9}
              color='#673AB7'
              ariaLabel='three-dots-loading'
              visible
            />
          </div>
        ) : (
          <>
            <div className='mb-4'>
              {price === 27 && (
                <span className='space-x-2 text-lg text-[#aa91d6] line-through'>
                  69
                </span>
              )}
              <span className='space-x-2 text-4xl font-bold text-[#673AB7]'>
                {price}
              </span>{' '}
              euros.
            </div>
            {clientId ? (
              <PayPalScriptProvider
                options={{ 'client-id': clientId, currency: 'EUR' }}
              >
                {isPending && <div className='spinner' />}
                <PayPalButtons
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            value: price.toString(),
                          },
                        },
                      ],
                    });
                  }}
                  onApprove={onApprove}
                  onError={onError}
                  className='w-[30rem]'
                />
              </PayPalScriptProvider>
            ) : (
              <p className='text-red-500'>
                Erreur : le client ID PayPal est introuvable.
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Payment;
