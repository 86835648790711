import React, { useEffect, useState } from 'react';
import CoursLayout from '../../components/admin/CoursLayout';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useGetCourseQuery,
  useUpdateCourseFieldMutation,
} from '../../redux/slices/courseApiSlice';
import Spinner from '../../components/spinner/Spinner';
import { useSelector } from 'react-redux';

const EdictTitle = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user.token;
  const params = useParams();
  const { id } = params;
  const location = useLocation();

  const [title, setTitle] = useState('');
  const maxCharacters = 60;

  const [isFormatted, setIsFormatted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);

  const [updateCourseField, { isSuccess, isLoading }] =
    useUpdateCourseFieldMutation();

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    if (typingTimeout) clearTimeout(typingTimeout);

    setTypingTimeout(
      setTimeout(() => {
        setHasUserInteracted(true);
      }, 1000)
    );
  };

  const {
    data,
    isSuccess: getSuccess,
    isLoading: getLoading,
    refetch,
  } = useGetCourseQuery(id);

  useEffect(() => {
    if (getSuccess && data?.title) {
      setTitle(data.title);
      setIsFormatted(true);
    }
  }, [getSuccess, location.pathname, refetch, data?.title]);

  const updateField = async () => {
    try {
      await updateCourseField({
        id: id,
        token: token,
        field: 'title',
        value: title,
      }).unwrap();
      refetch();
    } catch (error) {
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
      setErrorMessage(error.data.message);
    }
  };

  useEffect(() => {
    if (hasUserInteracted) {
      updateField();
      setHasUserInteracted(false);
    }
  }, [title, hasUserInteracted]);

  return (
    <main className={`flex-1`}>
      <CoursLayout id={id}>
        <div className='w-full'>
          <div className=' w-full p-4  rounded-lg mb-4 shadow-sm bg-white'>
            {errorMessage && (
              <div className=' w-full flex p-2 mb-2 items-center bg-red-100 space-x-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='h-5 text-red-600'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z'
                  />
                </svg>

                <span className='   flex   text-red-600'>{errorMessage}</span>
              </div>
            )}
            <div className='w-full  flex justify-between items-center'>
              <h4 className='text-[1.1rem] mb-2 '>
                Le titre de votre cours est modifiable.
              </h4>
            </div>

            <div className='w-full '>
              <input
                type='text'
                value={title}
                onChange={handleTitleChange}
                placeholder='par ex. Apprendre Photoshop CS6 de A à Z'
                maxLength={maxCharacters}
                className='w-full p-4 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-[#394dd0]'
              />
              <div className='text-right text-gray-400 mt-1'>
                {maxCharacters - title.length} caractères restants
              </div>
            </div>
            {isLoading && <Spinner />}
          </div>
        </div>
      </CoursLayout>
    </main>
  );
};

export default EdictTitle;
