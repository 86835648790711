const API_URL = 'https://api.academieceisna.org';
// ${API_URL}
export const USER_URL = '/users';
export const BASE_URL = `${API_URL}/api/`;
export const COURSES_URL = '/courses';
export const RESGISTERS_URL = '/learn';
export const ASSESSMENT_URL = '/assessments';
export const FAQS_URL = '/faqs';
export const REVIEW_URL = '/reviews';
export const STUDENT_ASSEMENT_URL = '/students/assessments';
export const CERTIFICAT_URL = '/certificat';
