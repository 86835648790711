import React from 'react';
import FullButton from '../utilities/FullButton';
import { useNavigate } from 'react-router-dom';
import banner from '../../assets/banner.png';

const Banner = () => {
  const navigate = useNavigate();
  return (
    <section className='w-full relative   overflow-hidden bg-white py-12'>
      {/* <div
        style={{
          background: `url(${banner})`,
          height: '100%',
          width: '100%',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        }}
      ></div> */}
      <div className='text-start  relative w-[90%] mx-auto '>
        <div className='  bg-[#ffffff33] relative w-[100%] z-50 md:w-[80%]'>
          <h1 className=' text-2xl md:text-4xl font-bold text-gray-800 mb-6'>
            Développez vos compétences et faites évoluer votre carrière avec{' '}
            <span className='text-blue-600'>Ceisna.</span>
          </h1>
          <p className='text-lg text-gray-600 mb-8'>
            Ceisna vous offre des formations professionnelles de haute qualité,
            conçues pour vous aider à atteindre vos objectifs professionnels
            rapidement et efficacement. Que vous soyez débutant ou expert, nos
            cours sont adaptés à tous les niveaux.
          </p>
          <div>
            <FullButton
              onClick={() => navigate('admin/my-courses')}
              text='  Commencez'
            />
          </div>
        </div>
        <div className='absolute -right-28 -top-14'>
          <img
            src='https://static.licdn.com/aero-v1/sc/h/cch95u9n3wpbme2zzh5n4uwg0'
            alt='coures image'
            className=' w-[500px] relative h-[500px] '
          />
        </div>
      </div>
    </section>
  );
};

export default Banner;
