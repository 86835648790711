import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import RoundeButton from '../utilities/RoundeButton';
import { formatDate, formatTime } from '../../utils/utils';
import StarRating from './StarRating';
import { useSelector } from 'react-redux';

const VideoPopup = ({ togglePopup, videoUrl, onClose }) => {
  return (
    <div
      onClick={togglePopup}
      className='fixed z-[999999] inset-0 bg-[#0000003d] bg-opacity-75 flex items-center justify-center '
    >
      <div className='relative bg-white p-4 rounded-lg max-w-2xl w-full'>
        <button
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          className='absolute top-2 right-2 text-gray-600 hover:text-gray-800'
        >
          ✕
        </button>
        <div
          className='w-full rounded-md overflow-hidden'
          style={{ height: '40vh' }}
        >
          <video
            src={videoUrl}
            controls
            className='w-full h-full rounded-md'
            style={{ objectFit: 'cover' }}
          />
        </div>
      </div>
    </div>
  );
};

const DetailsBanner = ({ reviews, data }) => {
  const [showPopup, setShowPopup] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const goToCourse = () => {
    if (data.student.includes(user?._id)) {
      navigate(`/course/learning/${data._id}`);
    } else {
      navigate(`/payement/student/${data._id}`);
    }
  };

  const togglePopup = () => setShowPopup(!showPopup);
  return (
    <section className='w-full border-b  relative  overflow-hidden bg-white '>
      <div className='absolute w-[45vw] z-0 -right-0 top-0 bottom-0'>
        <img
          src={data?.imageUrl}
          alt='coures image'
          className=' w-[100%] object-cover  relative h-[500px]'
        />
      </div>
      <div className=' py-16 bg-banner text-start z-30  relative w-[100%] l:w-[90%] mx-auto '>
        <div className=' px-2 space-y-2 w-[100%]  md:w-[80%]'>
          <Link
            to={'/'}
            className=' text-[1.8rem] md:text-[3rem] leading-10 font-medium text-gray-800 mb-6 hover:text-[#0b0272] hover:underline hover:decoration-[#0b0272]'
          >
            {data?.title}
          </Link>
          <div className=' justify-between flex items-center'>
            <div className=' py-4 mspace-y-2 flex flex-col'>
              <ul className=' md:space-x-5 flex items-start'>
                <li>
                  <span> {data?.level}</span>
                </li>
                <li>
                  <span> {formatTime(data.time)} </span>
                </li>
                <li>
                  <span>
                    Publié le {formatDate(data.published.publishedAt)}
                  </span>
                </li>
              </ul>
              <div className=' flex items-center'>
                <ul className='  space-x-6 flex items-center'>
                  <StarRating star={reviews?.reviews[0]?.rating || 0} />
                  <li>
                    <span>{data.student.length} apprenants</span>
                  </li>
                  <li>
                    <span className=' font-medium'>{data.price}€</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className=' hidden md:block'>
              <button
                onClick={togglePopup}
                className='mt-4  flex items-center space-x-2 bg-[#000000b8] rounded-full text-white px-4 py-2'
              >
                <span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    fill='currentColor'
                    className='size-6'
                  >
                    <path
                      fillRule='evenodd'
                      d='M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z'
                      clipRule='evenodd'
                    />
                  </svg>
                </span>
                <span>Aperçu</span>
              </button>
            </div>
          </div>

          <div className=' items-center flex space-x-3'>
            <RoundeButton
              onClick={goToCourse}
              text={`${
                data && data.student && data.student.includes(user?._id)
                  ? 'Aller au cours'
                  : 'Commencez maintenant'
              } `}
            />
            <button
              onClick={togglePopup}
              className=' md:hidden   flex items-center space-x-2 bg-[#000000b8] rounded-full text-white px-4 py-2'
            >
              <span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className='size-6'
                >
                  <path
                    fillRule='evenodd'
                    d='M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z'
                    clipRule='evenodd'
                  />
                </svg>
              </span>
              <span>Aperçu</span>
            </button>
          </div>
        </div>
      </div>
      {showPopup && (
        <VideoPopup
          togglePopup={togglePopup}
          videoUrl={data?.promotion}
          onClose={togglePopup}
        />
      )}
    </section>
  );
};

export default DetailsBanner;
