import React, { useEffect, useState, useMemo } from 'react';
import CoursLayout from '../../components/admin/CoursLayout';
import Spinner from '../../components/spinner/Spinner';
import { useSelector } from 'react-redux';
import { useGetUsersQuery } from '../../redux/slices/userApiSlice';
import { useParams } from 'react-router-dom';
import {
  useGetCourseQuery,
  useUpdateCourseFieldMutation,
} from '../../redux/slices/courseApiSlice';
import StudentCard from '../../components/Student/StudentCard';

const EdictStudent = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user.token;
  const { id } = useParams();

  const [studentName, setStudentName] = useState('');
  const [addedStudents, setAddedStudents] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  // Query pour obtenir les étudiants
  const {
    data: students,
    isFetching,
    refetch,
  } = useGetUsersQuery({
    token,
    type: 'students',
    name: '',
  });

  const { data: courseData, isSuccess: getSuccess } = useGetCourseQuery(id);

  const [updateCourseField, { isLoading: isUpdating, isSuccess }] =
    useUpdateCourseFieldMutation();

  // Fonction pour ajouter ou retirer un étudiant
  const addOrRemoveStudent = async (studentId) => {
    const studentExists = addedStudents.some(
      (student) => student._id === studentId
    );

    const updatedStudents = studentExists
      ? addedStudents.filter((student) => student._id !== studentId)
      : [
          ...addedStudents,
          students?.students.find((student) => student._id === studentId),
        ];

    try {
      await updateCourseField({
        id,
        token,
        field: 'student',
        value: updatedStudents.map((student) => student._id),
      }).unwrap();

      setAddedStudents(updatedStudents);
    } catch (error) {
      setErrorMessage(
        error.data?.message || 'Erreur lors de la mise à jour des étudiants.'
      );
      setTimeout(() => setErrorMessage(''), 5000);
    }
  };

  useEffect(() => {
    if (courseData && courseData.student && students?.students) {
      const studentIds = courseData.student;
      const initialStudents = students.students.filter((student) =>
        studentIds.includes(student._id)
      );
      setAddedStudents(initialStudents);
    }
  }, [courseData, students?.students]);

  const filteredStudentList = useMemo(() => {
    const studentAdded = new Set(addedStudents.map((student) => student._id));

    return (
      students?.students?.filter(
        (student) =>
          !studentAdded.has(student._id) &&
          student.name.toLowerCase().includes(studentName.toLowerCase())
      ) || []
    );
  }, [students?.students, isSuccess, addedStudents, studentName]);

  return (
    <main className='flex-1'>
      <CoursLayout id={id}>
        <div className='w-full'>
          <div className='w-full p-4 rounded-lg mb-4 shadow-sm bg-white'>
            {errorMessage && (
              <div className='w-full flex p-2 mb-2 items-center bg-red-100 space-x-1'>
                <span className='text-red-600'>{errorMessage}</span>
              </div>
            )}
            <div className='w-full flex justify-between items-center'>
              <h4 className='text-[1.1rem] mb-2'>Étudiants</h4>
            </div>
            <div className='w-full mt-1 mb-4'>
              <input
                type='text'
                placeholder='Rechercher un étudiant'
                value={studentName}
                onChange={(e) => setStudentName(e.target.value)}
                className='p-2 border w-full rounded flex-1'
              />
            </div>
          </div>
          {isFetching && <Spinner />}
          <div className='grid mt-2 pb-3 grid-cols-1   gap-4 lg:grid-cols-2 2xl:grid-cols-3'>
            {filteredStudentList?.length > 0 ? (
              filteredStudentList.map((student) => (
                <StudentCard
                  key={student._id}
                  student={student}
                  onClick={addOrRemoveStudent}
                />
              ))
            ) : (
              <p>Aucun étudiant trouvé.</p>
            )}
          </div>
          {addedStudents.length > 0 && <h4>Etudiant(e)s assoccié(e)s</h4>}

          <div className='grid mt-2 pb-3 grid-cols-1   gap-4 lg:grid-cols-2 2xl:grid-cols-3'>
            {addedStudents.map((student) => (
              <StudentCard
                key={student._id}
                student={student}
                onClick={addOrRemoveStudent}
              />
            ))}
          </div>
        </div>
      </CoursLayout>
    </main>
  );
};

export default EdictStudent;
