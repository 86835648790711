import { useState } from 'react';
import { useDeleteCoursesMutation } from '../../redux/slices/courseApiSlice';
import { Link, useNavigate } from 'react-router-dom';
import { formatTime } from '../../utils/utils';
import { useSelector } from 'react-redux';
import { FaEdit, FaTrash } from 'react-icons/fa';

const AdminCoursItem = ({ refetch, item, setErrorMessage, errorMessage }) => {
  const navigate = useNavigate();
  const [deleteCourses, { isLoading, isSuccess }] = useDeleteCoursesMutation();
  const { user } = useSelector((state) => state.auth);
  const token = user.token;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const deleteHandler = async () => {
    try {
      await deleteCourses({ token: token, id: item._id }).unwrap();
      setIsModalOpen(false);
      refetch();
    } catch (error) {
      setErrorMessage(error.data.message);
    }
  };

  const openModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsModalOpen(false);
  };

  const editHandler = () => {
    navigate(`/admin/create-cours/title/edict/${item._id}`);
  };

  return (
    <Link
      to={`/cours/${item._id}`}
      className='bg-white shadow-md rounded-lg overflow-hidden transition-transform transform hover:scale-105'
    >
      <div className='relative h-40'>
        <img
          src={item?.imageUrl}
          alt='Cours image'
          className='object-cover w-full h-full'
        />
        {item?.populaire && (
          <span className='absolute top-2 left-2 bg-purple-600 text-white text-xs font-semibold px-3 py-1 rounded shadow-lg'>
            Populaire
          </span>
        )}
      </div>
      <h3 className=' text-sm md:text-lg px-2 font-semibold text-gray-800 truncate'>
        {item.title}
      </h3>
      <div className='text-sm w-full px-2 flex items-center text-gray-500'>
        <p
          dangerouslySetInnerHTML={{
            __html:
              item?.description && item.description.length > 46
                ? item.description
                    .replace(/<\/?p[^>]*>/g, ' ') // Remplace les balises <p> par des espaces
                    .replace(/<br\s*\/?>/g, ' ') // Remplace les balises <br> par des espaces
                    .substring(0, 46) + '...' // Ajoute des points de suspension si la description est trop longue
                : item?.description || '', // Fournit une chaîne vide si la description est undefined
          }}
        />
      </div>

      <p className='text-sm text-gray-500 px-2'>{formatTime(item?.time)}</p>
      <div className='flex mt-2 px-2 py-1 border-t items-center justify-between'>
        <p className='text-sm text-gray-700 '>Par : {item?.user?.name}</p>
        <div className='flex justify-end  space-x-2 '>
          <button
            className='bg-lime-50 hover:bg-lime-100 transition-all rounded-full p-2'
            onClick={(e) => {
              e.preventDefault();
              editHandler();
            }}
          >
            <FaEdit className='h-4 text-lime-700' />
          </button>
          <button
            className='bg-red-50 hover:bg-red-100 transition-all rounded-full p-2'
            onClick={openModal}
          >
            <FaTrash className='h-4 text-red-600' />
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          onClick={closeModal}
          className='fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50'
        >
          <div className='bg-white p-6 rounded-md shadow-md w-80'>
            <h3 className='text-lg font-semibold'>Confirmer la suppression</h3>
            <p className='text-gray-700 mt-2'>
              Êtes-vous sûr de vouloir supprimer ce cours ?
            </p>
            <div className='flex justify-end space-x-4 mt-4'>
              <button
                onClick={closeModal}
                className='px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400'
              >
                Annuler
              </button>
              <button
                onClick={deleteHandler}
                className='px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600'
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      )}
    </Link>
  );
};

export default AdminCoursItem;
