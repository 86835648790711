import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useLocation, useParams } from 'react-router-dom';
import CoursLayout from '../../components/admin/CoursLayout';
import {
  useGetCourseQuery,
  useUpdateCourseFieldMutation,
} from '../../redux/slices/courseApiSlice';
import Spinner from '../../components/spinner/Spinner';
import { useSelector } from 'react-redux';

const EdictDescription = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user.token;
  const [description, setDescription] = useState();
  const { id } = useParams();
  const location = useLocation();

  const [errorMessage, setErrorMessage] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);

  const handleQuillChange = (value) => {
    setDescription(value);
    if (typingTimeout) clearTimeout(typingTimeout);

    setTypingTimeout(
      setTimeout(() => {
        setHasUserInteracted(true);
      }, 1000)
    );
  };

  const [updateCourseField, { isLoading }] = useUpdateCourseFieldMutation();
  const {
    data,
    isSuccess: getSuccess,
    refetch,
    isLoading: loading,
  } = useGetCourseQuery(id);

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  useEffect(() => {
    if (getSuccess && data?.description) {
      setDescription(data.description);
    }
  }, [getSuccess, loading, location.pathname, data]);

  const updateField = async () => {
    try {
      await updateCourseField({
        id,
        token: token,
        field: 'description',
        value: description,
      }).unwrap();
    } catch (error) {
      setErrorMessage(error?.data?.message || 'Erreur lors de la mise à jour');
      setTimeout(() => setErrorMessage(''), 5000);
    }
  };

  useEffect(() => {
    if (hasUserInteracted) {
      updateField();
      setHasUserInteracted(false);
    }
  }, [description, hasUserInteracted]);

  return (
    <div>
      <CoursLayout id={id}>
        <div className='w-full'>
          <div className='p-6 bg-white rounded-lg shadow-lg'>
            {errorMessage && (
              <div className='w-full flex p-2 mb-2 items-center bg-red-100 space-x-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='h-5 text-red-600'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z'
                  />
                </svg>
                <span className='flex text-red-600'>{errorMessage}</span>
              </div>
            )}
            <h4 className='text-[1.1rem] mb-2'>Description du cours</h4>
            <ReactQuill
              theme='snow'
              style={{ minHeight: '100px' }}
              value={description}
              onChange={handleQuillChange}
              className='mb-4'
            />

            {isLoading && <Spinner />}
          </div>
        </div>
      </CoursLayout>
    </div>
  );
};

export default EdictDescription;
