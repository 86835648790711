import { COURSES_URL } from '../constants/constants';
import { apiSlice } from './apiSlice';

export const courseApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addCourse: builder.mutation({
      query: (course) => ({
        url: COURSES_URL,
        method: 'POST',
        body: course,
        headers: {
          Authorization: `Bearer ${course.token}`,
        },
      }),
    }),

    updateCourseField: builder.mutation({
      query: ({ id, token, field, value }) => ({
        url: `${COURSES_URL}/${id}/${field}`,
        method: 'PUT',
        body: { [field]: value },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    addField: builder.mutation({
      query: ({ id, faq, value }) => ({
        url: `${COURSES_URL}/${id}/${value}`,
        method: 'PUT',
        body: { faq },
      }),
    }),

    updateReview: builder.mutation({
      query: ({ id, review }) => ({
        url: `${COURSES_URL}/${id}/review`,
        method: 'PUT',
        body: review,
      }),
    }),

    getCourse: builder.query({
      query: (id) => ({
        url: COURSES_URL + '/' + id,
        method: 'GET',
      }),
    }),

    getCourses: builder.query({
      query: ({ currentPage = '', title = '' }) => ({
        url: COURSES_URL,
        method: 'GET',
        params: { currentPage, title },
      }),
    }),

    tutorCourses: builder.query({
      query: ({ currentPage, title = '', id, token }) => ({
        url: COURSES_URL + '/' + 'tutors/',
        method: 'GET',
        params: { currentPage, title, id },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    deleteCourses: builder.mutation({
      query: ({ token, id }) => ({
        url: COURSES_URL + '/' + id,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    getCoursesStat: builder.query({
      query: () => ({
        url: COURSES_URL + '/stat/resume',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useAddCourseMutation,
  useUpdateCourseFieldMutation,
  useAddFieldMutation,
  useUpdateReviewMutation,
  useGetCourseQuery,
  useGetCoursesQuery,
  useDeleteCoursesMutation,
  useGetCoursesStatQuery,
  useTutorCoursesQuery,
} = courseApiSlice;
