import React, { useEffect } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/slices/authSlice';
import { useLogOutMutation } from '../../redux/slices/userApiSlice';
import { getInitials } from '../../utils/utils';
import {
  FaTachometerAlt,
  FaChalkboardTeacher,
  FaUserGraduate,
  FaBook,
  FaClipboardCheck,
  FaUser,
  FaSignOutAlt,
  FaCertificate,
  FaFileAlt,
} from 'react-icons/fa';

const DropdownMenu = ({ h, w, user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logOut, { isError, error, isLoading, isSuccess }] =
    useLogOutMutation();

  useEffect(() => {
    if (isSuccess && !isLoading) {
      dispatch(logout());
    }
  }, [isSuccess]);

  const logoutHandler = async () => {
    try {
      await logOut().unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const adminItems = [
    {
      url: '/admin/dasboard',
      texte: 'Tableau de bord',
      icon: <FaTachometerAlt />,
    },
    {
      url: '/admin/formateurs',
      texte: 'Formateurs',
      icon: <FaChalkboardTeacher />,
    },
    {
      url: '/admin/etudiants',
      texte: 'Étudiant(e)s',
      icon: <FaUserGraduate />,
    },

    {
      url: '/admin/evalution',
      texte: 'Évaluations',
      icon: <FaClipboardCheck />,
    },
    { url: '/admin/courses', texte: 'Cours', icon: <FaBook /> },
  ];

  const userItems = [
    {
      url: `/admin/my-courses`,
      texte: 'Mes cours',
      icon: <FaBook />,
    },
    {
      url: `/student/exams/`,
      texte: 'Examens',
      icon: <FaFileAlt />,
    },
    {
      url: `/student/certificat/`,
      texte: 'Certificats',
      icon: <FaCertificate />,
    },

    ,
  ];

  return (
    <div className=' relative'>
      <Menu>
        <MenuButton className='inline-flex items-center gap-2  bg-gray-50  rounded-full  text-sm/6 font-semibold  shadow-gray-100 px-2 py-[0.2rem] shadow-inner  focus:outline-none data-[hover]:bg-gray-100 data-[open]:bg-gray-100 data-[focus]:outline-1 data-[focus]:outline-white'>
          <div
            className={`${h} ${w} cursor-pointer rounded-full bg-gray-500 flex items-center justify-center text-white`}
          >
            {getInitials(user.name)}
          </div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className=' h-5'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='m19.5 8.25-7.5 7.5-7.5-7.5'
            />
          </svg>
        </MenuButton>

        <MenuItems
          transition
          anchor='bottom end'
          className='w-64  fixed origin-top-right bg-white z-[9999]   rounded-xl  shadow-lg p-1 text-sm/6  transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0'
        >
          <MenuItem className=' border-b-2 border-gray-100'>
            <button
              onClick={() => navigate('/admin/profile')}
              className='group flex w-full items-center gap-2  py-1.5 px-3 data-[focus]:bg-white/10'
            >
              {user.profileImage ? (
                <img
                  src={user.profileImage}
                  alt='User Profile'
                  className='w-12 h-12 rounded-full'
                />
              ) : (
                <div className=' flex items-center space-x-2'>
                  <div className='w-12 h-12 cursor-pointer rounded-full bg-gray-500 flex items-center justify-center text-white'>
                    {getInitials(user.name)}
                  </div>
                  <div className=' leading-[1.4] flex flex-col items-start'>
                    <span className=' font-medium  text-[1.1rem] capitalize'>
                      {user.name}
                    </span>
                    <span className=' text-[0.8rem] text-gray-500'>
                      {user.email}
                    </span>
                  </div>
                </div>
              )}
            </button>
          </MenuItem>
          {user && user.isAdmin
            ? adminItems.map((adminItem, index) => (
                <MenuItem className='' key={index}>
                  <button
                    onClick={() => navigate(adminItem.url)}
                    className='group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10'
                  >
                    {adminItem.icon}
                    {adminItem.texte}
                  </button>
                </MenuItem>
              ))
            : userItems.map((userItem, index) => (
                <MenuItem key={index}>
                  <button
                    onClick={() => navigate(userItem.url)}
                    className='group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10'
                  >
                    {userItem.icon}
                    {userItem.texte}
                  </button>
                </MenuItem>
              ))}

          <MenuItem>
            <button
              onClick={logoutHandler}
              className='group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10'
            >
              <FaSignOutAlt />
              Déconnexion
            </button>
          </MenuItem>
          <div className=' py-1 h-px bg-white/5' />
        </MenuItems>
      </Menu>
    </div>
  );
};

export default DropdownMenu;
