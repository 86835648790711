import { useEffect, useState } from 'react';
import CoursLayout from '../../components/admin/CoursLayout';
import SelectBox from '../../components/selectBox/SelectBox';
import { categories } from '../../data/data';
import { useLocation, useParams } from 'react-router-dom';
import {
  useGetCourseQuery,
  useUpdateCourseFieldMutation,
} from '../../redux/slices/courseApiSlice';
import Spinner from '../../components/spinner/Spinner';
import { useSelector } from 'react-redux';

const EdictCategorie = () => {
  const params = useParams();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const token = user.token;
  const { id } = params;
  const [category, setCategory] = useState('');
  const [isFormatted, setIsFormatted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasUserInteracted, setHasUserInteracted] = useState(false);

  const [updateCourseField, { isSuccess, isLoading }] =
    useUpdateCourseFieldMutation();

  const {
    data,
    isSuccess: getSuccess,
    isLoading: getLoading,
    refetch,
  } = useGetCourseQuery(id);

  useEffect(() => {
    if (getSuccess && data?.category) {
      const cate = categories.find(
        (categorie) => categorie.name === data.category
      );

      setCategory(cate);
      setIsFormatted(true);
    }
  }, [getSuccess, location.pathname, refetch, data?.category]);

  const updateField = async () => {
    try {
      await updateCourseField({
        id: id,
        token: token,
        field: 'category',
        value: category.name,
      }).unwrap();
      refetch();
    } catch (error) {
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
      setErrorMessage(error.data.message);
    }
  };

  useEffect(() => {
    if (hasUserInteracted) {
      updateField();
      setHasUserInteracted(false);
    }
  }, [category, hasUserInteracted]);

  return (
    <main className={` flex-1`}>
      <CoursLayout id={id}>
        <div className='w-full'>
          <div className=' w-full p-4  rounded-lg mb-4 shadow-sm bg-white'>
            {errorMessage && (
              <div className=' w-full flex p-2 mb-2 items-center bg-red-100 space-x-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='h-5 text-red-600'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z'
                  />
                </svg>

                <span className='   flex   text-red-600'>{errorMessage}</span>
              </div>
            )}
            <div className='w-full  flex justify-between items-center'>
              <h4 className='text-[1.1rem] mb-2 '>
                Sélectionnez une nouvelle catégorie pour le cours.
              </h4>
            </div>

            <div className='w-full mt-1  mb-4'>
              <SelectBox
                value={category}
                setValue={(newCert) => {
                  setCategory(newCert);
                  setHasUserInteracted(true);
                }}
                options={categories}
                defaultText='Veuillez selectionner la catégorie'
              />
            </div>

            {isLoading && <Spinner />}
          </div>
        </div>
      </CoursLayout>
    </main>
  );
};

export default EdictCategorie;
