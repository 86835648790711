import React, { useEffect, useState } from 'react';
import { cours } from '../../data/data';
import CourseCard from '../courseCard/CourseCard';

const Courses = ({ courses, title }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [index, setIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const [isPrevSliding, setIsPrevSliding] = useState(false);

  const nextHandler = () => {
    if (!isSliding) {
      setIsSliding(true);
      setTimeout(() => {
        if (courses.length <= index + 5) {
          setIndex(0);
        } else {
          setIndex((prevIndex) => prevIndex + 1);
        }
        setIsSliding(false);
      }, 300);
    }
  };

  const getCoursesToDisplay = () => {
    if (windowWidth >= 1536) return 5;
    if (windowWidth >= 1280) return 4;
    if (windowWidth >= 768) return 2;
    return 1;
  };

  useEffect(() => {
    getCoursesToDisplay();
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      getCoursesToDisplay();
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const prevHandler = () => {
    if (!isPrevSliding) {
      setIsPrevSliding(true);
      setTimeout(() => {
        if (index === 0) {
          setIndex(courses.length - 5);
        } else {
          setIndex((prevIndex) => prevIndex - 1);
        }
        setIsPrevSliding(false);
      }, 300);
    }
  };

  return (
    <div className='md:mx-12 mx-2 my-8 p-6 bg-white shadow-md  rounded-md'>
      <div className='flex items-center justify-between'>
        <h2 className='text-[1.2rem] font-semibold'>{title}</h2>
        <div className='flex items-center space-x-2'>
          <button
            onClick={prevHandler}
            className='border-[1px] border-gray-700 rounded-full p-2'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              fill='currentColor'
              className='h-6'
            >
              <path
                fillRule='evenodd'
                d='M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z'
                clipRule='evenodd'
              />
            </svg>
          </button>
          <button
            onClick={nextHandler}
            className='border-[1px] border-gray-700 rounded-full p-2'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              fill='currentColor'
              className='h-6'
            >
              <path
                fillRule='evenodd'
                d='M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z'
                clipRule='evenodd'
              />
            </svg>
          </button>
        </div>
      </div>
      <div className='relative w-full overflow-hidden'>
        <div
          className={`grid  grid-cols-1 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 pt-2 w-[110%] gap-4 transition-transform duration-300 ${
            isSliding ? 'transform translate-x-[-2%]' : ''
          } ${isPrevSliding ? 'transform translate-x-[2%]' : ''}  `}
        >
          {courses.slice(index, index + getCoursesToDisplay()).map((item) => (
            <CourseCard item={item} key={item._id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Courses;
