import { useEffect, useState } from 'react';
import CoursLayout from '../../components/admin/CoursLayout';
import SelectBox from '../../components/selectBox/SelectBox';
import { useLocation, useParams } from 'react-router-dom';
import {
  useGetCourseQuery,
  useUpdateCourseFieldMutation,
} from '../../redux/slices/courseApiSlice';
import { useSelector } from 'react-redux';

const dificulties = [
  { id: 1, name: 'Niveau 1' },
  { id: 2, name: 'Niveau 2' },
  { id: 3, name: 'Niveau 3' },
  { id: 4, name: 'Niveau 4' },
  { id: 5, name: 'Débutant' },
  { id: 6, name: 'Formation Certifiante' },
  { id: 7, name: 'Niveau Fondamental' },
  { id: 8, name: 'Niveau Expert' },
  { id: 9, name: 'Niveau Intermédiaire' },
  { id: 10, name: 'Niveau Spécialisé' },
  { id: 11, name: 'Niveau Avancé' },
  { id: 12, name: 'Niveau Intermédiaire' },
  { id: 13, name: 'Niveau Débutant' },
];

const EdictDificulty = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user.token;

  const { id } = useParams();
  const location = useLocation();
  const [level, setLevel] = useState('');
  const [hasUserInteracted, setHasUserInteracted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [updateCourseField, { isLoading: updateLoading }] =
    useUpdateCourseFieldMutation();
  const {
    data,
    isSuccess: getSuccess,
    isLoading: getLoading,
  } = useGetCourseQuery(id);

  useEffect(() => {
    if (getSuccess && data?.level) {
      const lev = dificulties.find((leve) => leve.name === data.level);

      if (lev) {
        setLevel(lev);
      } else {
        setLevel('');
      }
    }
  }, [getSuccess, location.pathname, data]);

  const updateField = async () => {
    try {
      await updateCourseField({
        id,
        token: token,
        field: 'level',
        value: level.name,
      }).unwrap();
    } catch (error) {
      setErrorMessage(error?.data?.message || 'Erreur lors de la mise à jour');
      setTimeout(() => setErrorMessage(''), 5000);
    }
  };

  useEffect(() => {
    if (hasUserInteracted) {
      updateField();
      setHasUserInteracted(false);
    }
  }, [level, hasUserInteracted]);

  return (
    <main className='flex-1'>
      <CoursLayout id={id}>
        <div className='w-full'>
          <div className='w-full p-4 rounded-lg mb-4 shadow-sm bg-white'>
            <div className='w-full flex justify-between items-center'>
              <h4 className='text-[1.1rem] mb-2'>Niveau du cours</h4>
            </div>

            <div className='w-full mt-1 mb-4'>
              <SelectBox
                value={level}
                setValue={(newLevel) => {
                  setLevel(newLevel);
                  setHasUserInteracted(true);
                }}
                options={dificulties}
                defaultText='Sélectionner le niveau du cours'
              />
            </div>

            {errorMessage && <p className='text-red-500'>{errorMessage}</p>}
          </div>
        </div>
      </CoursLayout>
    </main>
  );
};

export default EdictDificulty;
