import React, { useEffect, useState, useMemo } from 'react';
import CoursLayout from '../../components/admin/CoursLayout';
import Spinner from '../../components/spinner/Spinner';
import { useSelector } from 'react-redux';
import { useGetUsersQuery } from '../../redux/slices/userApiSlice';
import { useLocation, useParams } from 'react-router-dom';
import {
  useGetCourseQuery,
  useUpdateCourseFieldMutation,
} from '../../redux/slices/courseApiSlice';
import StudentCard from '../../components/Student/StudentCard';

const EdictInstructor = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user.token;
  const { id } = useParams();

  const [tutorName, setTutorName] = useState('');
  const [addedInstructors, setAddedInstructors] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const { data: instructors, isFetching } = useGetUsersQuery({
    token,
    type: 'instructors',
    name: '',
  });

  const { data: courseData, isSuccess: getSuccess } = useGetCourseQuery(id);

  // Mutation pour mettre à jour le champ "student" du cours
  const [updateCourseField, { isLoading: isUpdating, isSuccess }] =
    useUpdateCourseFieldMutation();

  // Fonction pour ajouter ou retirer un étudiant
  const addOrRemoveStudent = async (studentId) => {
    const studentExists = addedInstructors.some(
      (student) => student._id === studentId
    );

    const updatedInstructors = studentExists
      ? addedInstructors.filter((student) => student._id !== studentId) // Retirer l'étudiant
      : [
          ...addedInstructors,
          instructors?.instructors.find((student) => student._id === studentId),
        ]; // Ajouter l'étudiant

    try {
      await updateCourseField({
        id,
        token,
        field: 'teacher',
        value: updatedInstructors.map((student) => student._id),
      }).unwrap();

      setAddedInstructors(updatedInstructors);
    } catch (error) {
      setErrorMessage(
        error.data?.message || 'Erreur lors de la mise à jour des étudiants.'
      );
      setTimeout(() => setErrorMessage(''), 5000);
    }
  };

  // Récupération initiale des étudiants déjà ajoutés au cours
  useEffect(() => {
    if (courseData && courseData.teacher && instructors?.instructors) {
      const teacherIds = courseData.teacher;
      const initialInstructors = instructors.instructors.filter((teacher) =>
        teacherIds.includes(teacher._id)
      );

      setAddedInstructors(initialInstructors);
    }
  }, [courseData, instructors?.instructors]);

  // Mémorisation de la liste des étudiants en fonction du champ "name" pour éviter les recalculs inutiles
  const filteredStudentList = useMemo(() => {
    const instructorsFilter = new Set(
      addedInstructors.map((instructor) => instructor._id)
    );

    return (
      instructors?.instructors.filter(
        (teacher) =>
          !instructorsFilter.has(teacher._id) &&
          teacher.name.toLowerCase().includes(tutorName.toLowerCase())
      ) || []
    );
  }, [instructors?.instructors, isSuccess, addedInstructors, tutorName]);

  return (
    <main className='flex-1'>
      <CoursLayout id={id}>
        <div className='w-full'>
          <div className='w-full p-4 rounded-lg mb-4 shadow-sm bg-white'>
            {errorMessage && (
              <div className='w-full flex p-2 mb-2 items-center bg-red-100 space-x-1'>
                <span className='text-red-600'>{errorMessage}</span>
              </div>
            )}
            <div className='w-full flex justify-between items-center'>
              <h4 className='text-[1.1rem] mb-2'>Instructeur</h4>
            </div>
            <div className='w-full mt-1 mb-4'>
              <input
                type='text'
                placeholder='Rechercher un instructeur'
                value={tutorName}
                onChange={(e) => setTutorName(e.target.value)}
                className='p-2 border w-full rounded flex-1'
              />
            </div>
          </div>

          {isFetching && <Spinner />}
          <div className='grid mt-2 pb-3 grid-cols-1   gap-4 lg:grid-cols-2 2xl:grid-cols-3'>
            {filteredStudentList?.length > 0 ? (
              filteredStudentList.map((student) => (
                <StudentCard
                  key={student._id}
                  student={student}
                  onClick={addOrRemoveStudent}
                />
              ))
            ) : (
              <p>Aucun Instructeur trouvé.</p>
            )}
          </div>
          {addedInstructors.length > 0 && <h4>Instructeurs assocciés</h4>}

          <div className='grid mt-2 pb-3 grid-cols-1   gap-4 lg:grid-cols-2 2xl:grid-cols-3'>
            {addedInstructors.map((student) => (
              <StudentCard
                key={student._id}
                student={student}
                onClick={addOrRemoveStudent}
              />
            ))}
          </div>
        </div>
      </CoursLayout>
    </main>
  );
};

export default EdictInstructor;
