import React, { useEffect, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import CoursLayout from '../../components/admin/CoursLayout';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  useAddAssementMutation,
  useDeleteAssementsMutation,
  useGetAssementsQuery,
} from '../../redux/slices/assementApiSlice';

const EdictEvaluation = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const params = useParams();
  const { id } = params;

  const [addAssement, { isLoading, isSuccess, isError, data }] =
    useAddAssementMutation();

  const [deleteAssements, { isSuccess: deleteSucecss }] =
    useDeleteAssementsMutation();

  const {
    data: assessment,
    isLoading: loading,
    isSuccess: success,
    isError: error,
    refetch,
  } = useGetAssementsQuery({ token, id });

  const [evaluations, setEvaluations] = useState([]);
  const [newChoice, setNewChoice] = useState('');
  const [newQuestion, setNewQuestion] = useState('');
  const [newEvaluation, setNewEvaluation] = useState({
    type: 'Sujet',
    content: '',
    choices: [],
    question: [],
    timeLimit: '',
    description: '',
  });

  useEffect(() => {
    if (success) {
      setEvaluations(assessment);
    }
  }, [success]);

  useEffect(() => {
    if (deleteSucecss) {
      refetch();
    }
  }, [deleteSucecss]);

  useEffect(() => {
    if (isSuccess) {
      setEvaluations([...evaluations, data]);
      setNewEvaluation({
        type: 'Sujet',
        content: '',
        choices: [],
        question: [],
        timeLimit: '',
        description: '',
      });
    }
  }, [isSuccess]);

  const addEvaluation = async () => {
    if (newEvaluation.content.trim()) {
      try {
        await addAssement({
          ...newEvaluation,
          course: id,
          token: token,
        }).unwrap();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleInputChange = (e) => {
    setNewEvaluation({ ...newEvaluation, content: e.target.value });
  };

  const handleTypeChange = (e) => {
    setNewEvaluation({ ...newEvaluation, type: e.target.value });
  };

  const handledDescriptionChange = (e) => {
    setNewEvaluation({ ...newEvaluation, description: e.target.value });
  };

  const handleChoiceChange = (e) => {
    setNewChoice(e.target.value);
  };

  const handleQuestionChange = (e) => {
    setNewQuestion(e.target.value);
  };

  const addQuestion = () => {
    if (newQuestion.trim()) {
      setNewEvaluation({
        ...newEvaluation,
        question: [...newEvaluation.question, newQuestion],
      });
      setNewQuestion('');
    }
  };

  const addChoice = () => {
    if (newChoice.trim()) {
      setNewEvaluation({
        ...newEvaluation,
        choices: [...newEvaluation.choices, newChoice],
      });
      setNewChoice('');
    }
  };

  const handleDeleteChoice = (index) => {
    const updatedChoices = newEvaluation.choices.filter((_, i) => i !== index);
    setNewEvaluation({ ...newEvaluation, choices: updatedChoices });
  };

  const handleDeleteQuestion = (index) => {
    const updatedQuestion = newEvaluation.question.filter(
      (_, i) => i !== index
    );

    setNewEvaluation({ ...newEvaluation, question: updatedQuestion });
  };

  const moveChoice = (dragIndex, hoverIndex) => {
    const draggedChoice = newEvaluation.choices[dragIndex];
    const updatedChoices = update(newEvaluation.choices, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, draggedChoice],
      ],
    });
    setNewEvaluation({ ...newEvaluation, choices: updatedChoices });
  };

  // Gestion de la durée de l'évaluation
  const handleTimeLimitChange = (e) => {
    setNewEvaluation({ ...newEvaluation, timeLimit: e.target.value });
  };

  // Gestion de l'éditeur de texte riche
  const handleQuillChange = (value) => {
    setNewEvaluation({ ...newEvaluation, content: value });
  };

  const deleteHandler = async (evId) => {
    if (window.confirm('êtes vous sùr vouloir le supprimer?')) {
      try {
        const data = { token, evId };
        await deleteAssements(data).unwrap();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const Choice = ({ handleDelete, choice, index }) => {
    const [, ref] = useDrag({
      type: 'choice',
      item: { index },
    });
    const [, drop] = useDrop({
      accept: 'choice',
      hover: (item) => {
        if (item.index !== index) {
          moveChoice(item.index, index);
          item.index = index;
        }
      },
    });
    return (
      <div
        ref={(node) => ref(drop(node))}
        className='flex items-center justify-between mb-2'
      >
        <span>{choice}</span>
        <button
          className='text-red-500 hover:underline ml-4'
          onClick={() => handleDelete(index)}
        >
          Supprimer
        </button>
      </div>
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <main className='flex-1'>
        <CoursLayout id={id}>
          <div className=' w-full'>
            <div className='p-6 bg-white rounded-lg shadow-sm'>
              <h4 className='text-xl font-semibold mb-4'>
                Ajouter une évaluation
              </h4>

              <div className='flex flex-col mb-4'>
                <label htmlFor='type' className='mb-2 font-medium'>
                  Type d'évaluation
                </label>
                <select
                  id='type'
                  value={newEvaluation.type}
                  onChange={handleTypeChange}
                  className='p-2 border rounded-lg mb-4'
                >
                  <option value='Sujet'>Sujet</option>
                  <option value='texte'>Texte</option>
                  <option value='choix-multiple'>
                    Question à Choix Multiple
                  </option>
                  <option value='glisser-deposer'>Glisser-Déposer</option>
                </select>

                <label htmlFor='timeLimit' className='mb-2 font-medium'>
                  Durée de l'évaluation (minutes)
                </label>
                <input
                  type='number'
                  id='timeLimit'
                  value={newEvaluation.timeLimit}
                  onChange={handleTimeLimitChange}
                  placeholder="Entrez la durée de l'évaluation"
                  className='p-2 border rounded-lg mb-4'
                />

                <label htmlFor='content' className='mb-2 font-medium'>
                  {newEvaluation.type === 'glisser-deposer'
                    ? 'Titre'
                    : 'Question'}
                </label>

                {/* Éditeur de texte pour le type "texte" */}
                {newEvaluation.type === 'texte' ? (
                  <ReactQuill
                    theme='snow'
                    value={newEvaluation.content}
                    onChange={handleQuillChange}
                    className='mb-4'
                  />
                ) : (
                  <input
                    type='text'
                    id='content'
                    value={newEvaluation.content}
                    onChange={handleInputChange}
                    placeholder={`${
                      newEvaluation.type === 'glisser-deposer'
                        ? 'Entrez le titre'
                        : 'Entrez la question'
                    } `}
                    className='p-2 border rounded-lg mb-4'
                  />
                )}

                {newEvaluation.type === 'choix-multiple' && (
                  <>
                    <label htmlFor='choice' className='mb-2 font-medium'>
                      Réponses
                    </label>

                    <div className='flex mb-4'>
                      <input
                        type='text'
                        id='choice'
                        value={newChoice}
                        onChange={handleChoiceChange}
                        placeholder='Entrez une réponse'
                        className='p-2 border rounded-lg flex-1'
                      />
                      <button
                        onClick={addChoice}
                        className='ml-2 px-4 py-2 bg-gray-100 rounded-lg'
                      >
                        Ajouter
                      </button>
                    </div>

                    <div className='mb-4'>
                      {newEvaluation.choices.map((choice, index) => (
                        <Choice
                          handleDelete={handleDeleteChoice}
                          key={index}
                          index={index}
                          choice={choice}
                        />
                      ))}
                    </div>
                  </>
                )}

                {newEvaluation.type === 'glisser-deposer' && (
                  <>
                    <label htmlFor='choice' className='mb-2 font-medium'>
                      Qusetion
                    </label>
                    <div className='flex mb-4'>
                      <input
                        type='text'
                        id='choice'
                        value={newQuestion}
                        onChange={handleQuestionChange}
                        placeholder='Entrez une qusetion'
                        className='p-2 border rounded-lg flex-1'
                      />
                      <button
                        onClick={addQuestion}
                        className='ml-2 px-4 py-2 bg-gray-100 rounded-lg'
                      >
                        Ajouter
                      </button>
                    </div>
                    <div className='mb-4'>
                      {newEvaluation.question.map((q, index) => (
                        <Choice
                          handleDelete={handleDeleteQuestion}
                          key={index}
                          index={index}
                          choice={q}
                        />
                      ))}
                    </div>

                    <label htmlFor='choice' className='mb-2 font-medium'>
                      Réponses
                    </label>
                    <div className='flex mb-4'>
                      <input
                        type='text'
                        id='choice'
                        value={newChoice}
                        onChange={handleChoiceChange}
                        placeholder='Entrez une réponse'
                        className='p-2 border rounded-lg flex-1'
                      />
                      <button
                        onClick={addChoice}
                        className='ml-2 px-4 py-2 bg-gray-100 rounded-lg'
                      >
                        Ajouter
                      </button>
                    </div>

                    <div className='mb-4'>
                      {newEvaluation.choices.map((choice, index) => (
                        <Choice
                          handleDelete={handleDeleteChoice}
                          key={index}
                          index={index}
                          choice={choice}
                        />
                      ))}
                    </div>
                  </>
                )}

                <label htmlFor='timeLimit' className='mb-2 font-medium'>
                  Description
                </label>
                <textarea
                  id='timeLimit'
                  value={newEvaluation.description}
                  onChange={handledDescriptionChange}
                  placeholder='Entrez une description'
                  className='p-2 resize-none border rounded-lg mb-4'
                />

                <div>
                  <button
                    onClick={addEvaluation}
                    className='ml-2 px-4 py-2 bg-gray-100 rounded-lg'
                  >
                    Ajouter l'évaluation
                  </button>
                </div>
              </div>

              <h3 className='text-lg font-medium mb-4'>
                Évaluations programmées
              </h3>
              <ul>
                {evaluations.map((evaluation, index) => (
                  <li
                    key={index}
                    className='bg-gray-100 p-4 rounded-lg mb-2 relative'
                  >
                    <button
                      onClick={() => deleteHandler(evaluation._id)}
                      className=' absolute top-3 right-3'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 16 16'
                        fill='currentColor'
                        className='size-4 text-red-600'
                      >
                        <path
                          fillRule='evenodd'
                          d='M5 3.25V4H2.75a.75.75 0 0 0 0 1.5h.3l.815 8.15A1.5 1.5 0 0 0 5.357 15h5.285a1.5 1.5 0 0 0 1.493-1.35l.815-8.15h.3a.75.75 0 0 0 0-1.5H11v-.75A2.25 2.25 0 0 0 8.75 1h-1.5A2.25 2.25 0 0 0 5 3.25Zm2.25-.75a.75.75 0 0 0-.75.75V4h3v-.75a.75.75 0 0 0-.75-.75h-1.5ZM6.05 6a.75.75 0 0 1 .787.713l.275 5.5a.75.75 0 0 1-1.498.075l-.275-5.5A.75.75 0 0 1 6.05 6Zm3.9 0a.75.75 0 0 1 .712.787l-.275 5.5a.75.75 0 0 1-1.498-.075l.275-5.5a.75.75 0 0 1 .786-.711Z'
                          clipRule='evenodd'
                        />
                      </svg>
                    </button>
                    <strong>Type :</strong> {evaluation.type} <br />
                    <strong>Question :</strong> {evaluation.content} <br />
                    <strong>Durée :</strong> {evaluation.timeLimit} minutes
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </CoursLayout>
      </main>
    </DndProvider>
  );
};

export default EdictEvaluation;
