import { useCallback, useEffect, useMemo, useState } from 'react';
import CoursLayout from '../../components/admin/CoursLayout';
import SelectBox from '../../components/selectBox/SelectBox';
import { useLocation, useParams } from 'react-router-dom';
import {
  useGetCourseQuery,
  useUpdateCourseFieldMutation,
} from '../../redux/slices/courseApiSlice';
import Spinner from '../../components/spinner/Spinner';
import { useSelector } from 'react-redux';
import { useGetUsersQuery } from '../../redux/slices/userApiSlice';
import {
  useAddOrRemoveCertificatMutation,
  useGetCertificatsQuery,
} from '../../redux/slices/certicatApiSlice';
import CertificatCard from '../../components/certificat/CertificatCard';
import StudentCard from '../../components/Student/StudentCard';

const EdictCertificat = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const params = useParams();
  const { id } = params;
  const location = useLocation();
  const [
    addOrRemoveCertificat,
    { isLoading: laoding, isSuccess: addReSuccess },
  ] = useAddOrRemoveCertificatMutation();

  const [errorMessage, setErrorMessage] = useState('');
  const [certificat, setCertificat] = useState('');
  const [hasUserInteracted, setHasUserInteracted] = useState(false);
  const [useName, setUserName] = useState('');
  const [addedStudents, setAddedStudents] = useState([]);

  const certificatItems = [
    { name: 'Avec Certificat', id: 1, isTrue: true },
    { name: 'Sans Certifficat', id: 2, isTrue: false },
  ];

  const [updateCourseField, { isSuccess, isLoading }] =
    useUpdateCourseFieldMutation();

  const {
    data: certificats = [],
    isSuccess: certificatsSuccess,
    isLoading: certificatsLoading,
    refetch: certificatsRefetch,
  } = useGetCertificatsQuery(token);

  const {
    data,
    isSuccess: getSuccess,
    refetch,
    isLoading: loading,
  } = useGetCourseQuery(id);
  const {
    data: students,
    isFetching,
    refetch: refectStudent,
  } = useGetUsersQuery({
    token,
    type: 'students',
    name: '',
  });

  useEffect(() => {
    if (getSuccess && data) {
      const certi = certificatItems.find(
        (certificat) => certificat.isTrue === data.certificat
      );

      if (certi) {
        setCertificat(certi);
      } else {
        setCertificat('');
      }
    }
  }, [getSuccess, loading, location.pathname, data]);

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  const updateField = async () => {
    try {
      await updateCourseField({
        id: id,
        token: token,
        field: 'certificat',
        value: certificat.isTrue,
      }).unwrap();
    } catch (error) {
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
      setErrorMessage(error.data.message);
    }
  };

  useEffect(() => {
    if (hasUserInteracted) {
      updateField();
      setHasUserInteracted(false);
    }
  }, [certificat, hasUserInteracted]);

  // set certificate for student

  useEffect(() => {
    if (addReSuccess) {
      certificatsRefetch();
    }
  }, [addReSuccess]);

  const initializeAddedStudents = useCallback(() => {
    if (data?.student && students?.students) {
      const initialStudents = students.students.filter((student) =>
        data?.certificats?.includes(student._id)
      );
      setAddedStudents(initialStudents);
    }
  }, [data, students]);

  useEffect(() => {
    initializeAddedStudents();
  }, [initializeAddedStudents]);

  const addOrRemoveStudent = async (studentId) => {
    const isStudentAdded = addedStudents.some(
      (student) => student._id === studentId
    );

    const updatedStudents = isStudentAdded
      ? addedStudents.filter((student) => student._id !== studentId)
      : [
          ...addedStudents,
          students.students.find((student) => student._id === studentId),
        ];

    console.log(
      'Student',
      addedStudents.filter((student) => student._id !== studentId)
    );
    try {
      await Promise.all([
        updateCourseField({
          id,
          token,
          field: 'certificats',
          value: updatedStudents.map((student) => student._id),
        }).unwrap(),
        addOrRemoveCertificat({
          token,
          courseId: id,
          studentId,
        }).unwrap(),
      ]);

      setAddedStudents(updatedStudents);
    } catch (error) {
      setErrorMessage(
        error.data?.message || 'Erreur lors de la mise à jour des étudiants'
      );
      setTimeout(() => setErrorMessage(''), 5000);
    }
  };

  const filteredStudentList = useMemo(() => {
    const certifiedStudentIds = new Set(
      certificats.map((certificate) => certificate.studentId._id)
    );

    return students?.students?.filter(
      (student) =>
        !certifiedStudentIds.has(student._id) &&
        student.name.toLowerCase().includes(useName.toLowerCase())
    );
  }, [students, certificats, useName]);

  return (
    <main className=''>
      <CoursLayout id={id}>
        <div className=' w-full'>
          <div className='w-full p-4  rounded-lg shadow-sm bg-white'>
            <div className=' w-full   mb-4 '>
              {errorMessage && (
                <div className=' w-full flex p-2 mb-2 items-center bg-red-100 space-x-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='h-5 text-red-600'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z'
                    />
                  </svg>

                  <span className='   flex   text-red-600'>{errorMessage}</span>
                </div>
              )}
              <div className='w-full  flex justify-between items-center'>
                <h4 className='text-[1.1rem] mb-2 '>
                  Le cours est il accompagné d'un certificat?
                </h4>
              </div>

              <div className='w-full mt-1  mb-4'>
                <SelectBox
                  value={certificat}
                  setValue={(newLevel) => {
                    setCertificat(newLevel);
                    setHasUserInteracted(true);
                  }}
                  options={certificatItems}
                  defaultText='Veuillez selectionner le type de certificate'
                />
              </div>
            </div>
            <div className='w-full flex justify-between items-center'>
              <h4 className='text-[1.1rem] mb-2'>Attribuer un certificat</h4>
            </div>
            <div className='w-full mt-1 mb-4'>
              <input
                type='text'
                placeholder='Rechercher un étudiant'
                value={useName}
                onChange={(e) => setUserName(e.target.value)}
                className='p-2 border w-full rounded flex-1'
              />
            </div>
            {isFetching && <Spinner />}
          </div>
          <div className='grid mt-7 pb-3 border-b border-b-gray-300  grid-cols-1   gap-4 lg:grid-cols-2 2xl:grid-cols-3'>
            {filteredStudentList?.length > 0 ? (
              filteredStudentList.map((student) => (
                <StudentCard
                  key={student._id}
                  student={student}
                  onClick={addOrRemoveStudent}
                />
              ))
            ) : (
              <p>Aucun étudiant trouvé.</p>
            )}
          </div>
          {isLoading && <Spinner />}
          <div className='grid mt-6 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
            {certificats?.map((certificat) => (
              <CertificatCard
                isAdminEdition={true}
                removeStudent={addOrRemoveStudent}
                isAdim={true}
                key={certificat._id}
                certificat={certificat}
              />
            ))}
          </div>
        </div>
      </CoursLayout>
    </main>
  );
};

export default EdictCertificat;
