import React, { useEffect, useState } from 'react';
import CoursLayout from '../../components/admin/CoursLayout';
import { useLocation, useParams } from 'react-router-dom';
import {
  useGetCourseQuery,
  useUpdateCourseFieldMutation,
} from '../../redux/slices/courseApiSlice';
import Spinner from '../../components/spinner/Spinner';
import { useSelector } from 'react-redux';

const EdictCompetence = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user.token;
  const params = useParams();
  const { id } = params;
  const location = useLocation();

  const [skills, setSkills] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [hasUserInteracted, setHasUserInteracted] = useState(false);

  const [isFormatted, setIsFormatted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [updateCourseField, { isSuccess, isLoading }] =
    useUpdateCourseFieldMutation();

  const {
    data,
    isSuccess: getSuccess,
    isLoading: getLoading,
    refetch,
  } = useGetCourseQuery(id);

  useEffect(() => {
    if (getSuccess) {
      setIsFormatted(true);
    }

    if (getSuccess && data?.skills) {
      setSkills(data.skills);
    }
  }, [getSuccess, location.pathname, refetch, data?.skills]);

  const updateField = async () => {
    try {
      await updateCourseField({
        id: id,
        token: token,
        field: 'skills',
        value: skills,
      }).unwrap();

      refetch();
    } catch (error) {
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
      setErrorMessage(error.data.message);
    }
  };

  const handleAddCompetence = () => {
    if (inputValue) {
      setSkills([...skills, inputValue]);
      setInputValue('');
      setHasUserInteracted(true);
    }
  };

  useEffect(() => {
    if (hasUserInteracted) {
      updateField();
      setHasUserInteracted(false);
    }
  }, [skills, hasUserInteracted]);

  const handleDelete = (index) => {
    const newSkill = skills.filter((_, i) => i !== index);
    setSkills(newSkill);
    setHasUserInteracted(true);
  };

  return (
    <main className='flex-1'>
      <CoursLayout id={id}>
        <div className=' w-full'>
          <div className='p-6 bg-white rounded-lg shadow-sm'>
            {errorMessage && (
              <div className=' w-full flex p-2 mb-2 items-center bg-red-100 space-x-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='h-5 text-red-600'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z'
                  />
                </svg>

                <span className='   flex   text-red-600'>{errorMessage}</span>
              </div>
            )}
            <h4 className='text-xl font-semibold mb-4'>
              Ajoutez des compétences
            </h4>

            <div className='flex mb-2'>
              <input
                type='text'
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder='Ajouter une compétence'
                className='border rounded-lg p-2 flex-1'
              />
              {isLoading ? (
                <div className=' w-28'>
                  <Spinner />
                </div>
              ) : (
                <button
                  onClick={handleAddCompetence}
                  className='ml-2 px-4 py-2 bg-gray-100 rounded-lg'
                >
                  Ajouter
                </button>
              )}
            </div>
            <div className='flex gap-2 flex-wrap mb-2'>
              {skills.map((skill, index) => (
                <span key={index} className=' px-4 py-2 bg-gray-100 rounded-lg'>
                  {skill}
                  <button
                    className='ml-2 text-red-500'
                    onClick={() => handleDelete(index)}
                  >
                    x
                  </button>
                </span>
              ))}
            </div>
          </div>
        </div>
      </CoursLayout>
    </main>
  );
};

export default EdictCompetence;
