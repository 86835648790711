import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className=' px-2 py-5 border-t-2  border-gray-300'>
      <ul className=' space-y-2 md:space-y-0 flex-col flex md:flex-row md:items-center space-x-0 md:space-x-6'>
        <li>
          <Link
            className=' font-medium hover:underline  hover:decoration-blue-900'
            to=''
          >
            A propos de nous
          </Link>
        </li>
        <li className=' font-medium hover:underline  hover:decoration-blue-900'>
          <Link to=''>Contactez-nous</Link>
        </li>
        <li className=' font-medium hover:underline  hover:decoration-blue-900'>
          <Link to=''>Devenir instructeur</Link>
        </li>
        <li className=' font-medium hover:underline  hover:decoration-blue-900'>
          <Link to=''>Conditions générales et confidentialité</Link>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
