import { useEffect, useState } from 'react';
import Spinner from '../spinner/Spinner';

const TextComponent = ({
  loading,
  onSubmitHandle,
  content,
  onSubmit,
  isTimeOut,
}) => {
  const [answer, setAnswer] = useState('');

  const handleAutoSubmit = () => {
    onSubmit({ answer: answer });
  };

  useEffect(() => {
    if (isTimeOut) {
      handleAutoSubmit();
    }
  }, [isTimeOut]);
  return (
    <div>
      <h2 className='text-xl font-semibold mb-2'>Texte</h2>
      <p className='text-gray-700 mb-2'>{content}</p>
      <textarea
        className='border border-gray-300 rounded-md w-full p-2'
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        placeholder='Entrez votre réponse ici...'
      />

      {loading ? (
        <div className=' w-[12%]'>
          <Spinner />
        </div>
      ) : (
        <button
          onClick={() => onSubmitHandle({ answer: answer })}
          className='bg-black text-white px-4 py-2 ml-2'
        >
          Soumettre
        </button>
      )}
    </div>
  );
};

export default TextComponent;
