import { useLocation, useNavigate } from 'react-router-dom';
import LayoutItem from './LayoutItem';
import { MdExitToApp } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setSideBar } from '../../redux/slices/stateSlice';
import {
  useGetCourseQuery,
  useUpdateCourseFieldMutation,
} from '../../redux/slices/courseApiSlice';
import Spinner from '../../components/spinner/Spinner';

const Title = ({ texte }) => {
  return <h2 className='text-lg font-bold  mb-4'>{texte}</h2>;
};

const CoursLayout = ({ id = '', children }) => {
  const { user } = useSelector((state) => state.auth);
  const token = user.token;

  const conentItems = [
    { url: `/admin/create-cours/title/edict/${id}`, texte: 'Titre' },
    { url: `/admin/create-cours/categorie/edict/${id}`, texte: 'Catégories' },
    { url: `/admin/create-cours/programme/edict/${id}`, texte: 'Programme' },

    { url: `/admin/create-cours/certificat/edict/${id}`, texte: 'Certificat' },
    { url: `/admin/create-cours/evaluation/edict/${id}`, texte: 'Évaluation' },
    {
      url: `/admin/create-cours/dificulty/edict/${id}`,
      texte: 'Niveau du cours',
    },
    {
      url: `/admin/create-cours/description/edict/${id}`,
      texte: 'Description ',
    },

    {
      url: `/admin/create-cours/promotion/edict/${id}`,
      texte: "Vidéo d'apercu ",
    },

    {
      url: `/admin/create-cours/time/edict/${id}`,
      texte: 'Temps ',
    },

    {
      url: `/admin/create-cours/language/edict/${id}`,
      texte: 'Langue du cours',
    },
    {
      url: `/admin/create-cours/image/edict/${id}`,
      texte: 'Télécharger une image',
    },
    {
      url: `/admin/create-cours/competence/edict/${id}`,
      texte: 'Compétences couvertes',
    },
    {
      url: `/admin/create-cours/faqs/edict/${id}`,
      texte: 'FAQs',
    },
  ];

  const itemsPrice = [
    {
      url: `/admin/create-cours/price/edict/${id}`,
      texte: 'Prix ',
    },
    {
      url: `/admin/create-cours/promotionnal/edict/${id}`,
      texte: 'Prix promotionnel',
    },
  ];

  const communauties = [
    {
      url: `/admin/create-cours/student/edict/${id}`,
      texte: 'Etudiants ',
    },
    {
      url: `/admin/create-cours/instructor/edict/${id}`,
      texte: 'Formateur',
    },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [updateCourseField, { isSuccess, isLoading }] =
    useUpdateCourseFieldMutation();
  const [errorMessage, setErrorMessage] = useState('');

  const updateField = async () => {
    const published = {
      isPublished: true,
      publishedAt: new Date().toISOString(),
    };

    try {
      await updateCourseField({
        id: id,
        token: token,
        field: 'published',
        value: published,
      }).unwrap();
    } catch (error) {
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
      setErrorMessage(error.data.message);
    }
  };

  useEffect(() => {
    dispatch(setSideBar(false));
  }, [location.pathname]);

  useEffect(() => {
    if (isSuccess) {
      exitHandler();
    }
  }, [isSuccess]);

  const exitHandler = () => {
    navigate('/admin/courses');
  };

  return (
    <div className='flex mt-[4.5rem] space-x-10 pb-6  container m-auto min-h-[78vh] '>
      <div>
        <div className='w-64 bg-[#ffffff]   p-4'>
          <Title texte='Créer votre contenu' />
          <ul className=' w-full'>
            {conentItems.map((item, index) => (
              <LayoutItem key={index} {...item} />
            ))}
          </ul>
          <Title texte='Prix & Promotion' />
          <ul>
            {itemsPrice.map((item, index) => (
              <LayoutItem key={index} {...item} />
            ))}
          </ul>

          <Title texte='Communauté' />

          <ul className=' w-full'>
            {communauties.map((item, index) => (
              <LayoutItem key={index} {...item} />
            ))}
          </ul>

          {isLoading ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <button
              onClick={updateField}
              className=' mt-2 text-[1.1rem] w-full bg-red-600 text-white py-2'
            >
              Publier
            </button>
          )}
        </div>
        <button
          onClick={exitHandler}
          className='text-[#00075e] space-x-1 text-[1.1rem] flex items-center w-full mt-4 mb-1 hover:underline'
        >
          <span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='h-5'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3'
              />
            </svg>
          </span>
          <span>Quitter</span>
        </button>
      </div>

      {children}
    </div>
  );
};

export default CoursLayout;
